import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import BelgianEidJwtSnippet from '../../../snippets/jwt-payloads/belgian-eid';
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 8,
  "title": "Belgian eID",
  "subtitle": "Learn more about Belgian eID token contents, how to create test users and how to gain access to production."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`JWT/Token example`}</h2>
    <p>{`verified, with national number, personal info, photo and address enabled`}</p>
    <BelgianEidJwtSnippet mdxType="BelgianEidJwtSnippet" />
    <h2>{`Test users`}</h2>
    <p>{`This login method is smartcard based, so you will need both a smartcard reader and accompanying cards.`}</p>
    <p>{`For test domains, you can use both test-specific cards and also "live" cards. The only difference is that on test domains, the data from the card will not be verified after being read.`}</p>
    <p>{`For production domains, you cannot use test cards, as the data on these cannot be verified.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      